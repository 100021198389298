<template>
	<div class="form-group" :class="{'hide': (!props.show)}">
		<label class="contact-form-label" v-if="labelText.length > 0">{{ labelText }}
			<span v-if="!props.isRequired" class="optional-text"> (optional)</span>
		</label>
		<template v-if="dropDownOptions.length > 0">
			<div class="dropdown">
				<div :id="props.inputId" :key="props.key"
						class="contact-form-input contact-form-dropdown-selection dropdown-selected"
						@click="toggleDropdown">
				<span v-if="!props.selectedOption" :class="(dropdownText === '-- Please select --')? 'none-selected' : '' ">{{ dropdownText }}</span>
				<span v-else>{{ lookupMatch(props.selectedOption, dropDownOptions) }}</span>
				<nuxt-icon :name="(!isDropdownOpen) ? 'form-dropdown-arrow' : 'form-dropdown-arrow-up'"
					class="contact-form-dropdown-icon"/>
				</div>
				<Transition name="rolldown">
					<template v-if="isDropdownOpen">
						<ul class="gibs-filter-list" :class="scrollable" :value="props.selectedOption">
							<li class="gibs-form-dropdown-item disabled-input" @click="handleChange({id:'', itemText:''}, $event)">
								-- Please select --
							</li>
							<template v-for="option in dropDownOptions" :key="option">
								<li :value="option.id" class="gibs-form-dropdown-item" @click="handleChange(option, $event)">{{ option.itemText }}</li>
							</template>
						</ul>
					</template>
				</Transition>	
			</div>
		</template>
		<p :id="`${props.inputId}-error`" class="gibs-form-group-error gibs-small-regular"></p>
	</div>
</template>

<script setup lang="ts">

	const dropDownOptions = ref<Array<IDropdownItem>>([]);

	const props = defineProps({
			inputId: {
				type: String,
				required: true,
			},
			selectedOption: {
				default: ''
			},
			options: {
				type: Array<IDropdownItem>,
				required: true,
				default: []
			},
			labelText: {
				type: String,
				required: true,
				default: ''
			},
			isRequired: {
				type: Boolean,
				default: false,
			},
			validator: {
				type: Validation,
				default: null
			},
			validatorRuleId: {
				type: String,
				default: null
			},
			key : {
				type: Number
			},
			show: {
				type: Boolean,
				default: true
			},
			awaitOptions: {
				type: Function,
				default: async () => { return [] }
			},
			refreshKey : {
				type: Number,
				default: 0
			}
	});

	const emitFormDropdown = defineEmits<{
		dropdownSelection: [value: IDropdownItem]
	}>();

	const DEFAULT_TEXT = '-- Please select --';
	const dropdownText = ref<string>(DEFAULT_TEXT);
	const inputValue = ref<IDropdownItem>();
	const isDropdownOpen = ref(false);
	
	const scrollable = computed(() => {
		if (props.options && props.options.length > 10) {
			return "scrollable";
		} else {
			return "";
		}
	});
	
	function handleChange(selectedOption: IDropdownItem | String, event: Event) {
		if (typeof(selectedOption) === 'string') {
			selectedOption = {id: '', itemText: selectedOption};
		}

		const dropdownItemSelected = document.getElementById(props.inputId) as HTMLElement;
		if (dropdownItemSelected != null){
			if (dropdownItemSelected.classList.contains("dropdown-selected")){
				dropdownItemSelected.classList.remove("dropdown-selected");
			}
			dropdownItemSelected.classList.add("dropdown-selected");
		}

		inputValue.value = selectedOption;
		dropdownText.value = selectedOption.itemText;

		if (typeof(props.validator) !== 'undefined' && props.validator != null) {
			props.validator.setValidationRuleSourceValue(props.validatorRuleId, inputValue.value.id);
			let resultList = props.validator.validateRule(props.validatorRuleId);
			// the state applies error in the watcheffect
			ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId));
		}
		emitFormDropdown('dropdownSelection', inputValue.value);
	};

	function toggleDropdown() {
		let dropdownElement = document.getElementById(props.inputId)
		isDropdownOpen.value = !isDropdownOpen.value;
		if (isDropdownOpen.value) {
			dropdownElement?.classList.toggle("expanded-list");
			document.addEventListener('click', closeDropdown);
		} else {
			dropdownElement?.classList.toggle("expanded-list");
			document.removeEventListener('click', closeDropdown);
		}
	}
	
	function closeDropdown(event: Event) {
		let dropdownElement = document.getElementById(props.inputId);
		if (dropdownElement && !dropdownElement.contains(event.target as Node)) {
			isDropdownOpen.value = false;
			dropdownElement?.classList.toggle("expanded-list");
			document.removeEventListener('click', closeDropdown);
		}
	}
	
	function ApplyValidate(isInvalid: boolean) {
		const inputElement = document.getElementById(props.inputId) as HTMLElement;
		const errorElement = document.getElementById(`${props.inputId}-error`) as HTMLElement;
	
		if (isInvalid) {
			inputElement?.classList.add('has-error');
		}
		else {
			inputElement?.classList.remove('has-error');
		}
	
		let errorString = '';
		if (typeof (props.validator) !== 'undefined' && props.validator != null) {
			errorString = props.validator.getValidationResultString(props.validatorRuleId);
		}
		if (errorElement != null){
			errorElement.innerText = errorString;
		}
	}
	
	function lookupMatch(id: string, lookupList: IDropdownItem[]) {
		let value = lookupList.find((lookup: IDropdownItem) => lookup.id.toString() === id.toString());
		if (value) {
			if ((inputValue.value?.itemText??"")  != value.itemText)
			{
				handleChange(value, null);
			}
			return value.itemText;
		} else {
			value = lookupList.find((lookup: IDropdownItem) => lookup.itemText.toString() === id.toString());
			if (value != null){
				lookupMatch(value.id, lookupList);
			}
			
			console.log('Invalid ID');
			console.log('id: ', id);
			console.log('lookupList: ', JSON.stringify(lookupList, null, 2));
			//return DEFAULT_TEXT;
			return 'Invalid ID';
		}
		
	}

	watch( () => inputValue.value , (newValue, oldValue) => {
			if (newValue?.id === '') {
				const dropdownItemSelected = document.getElementById(props.inputId) as HTMLElement;
				if (dropdownItemSelected != null) {
					dropdownItemSelected.classList.remove("dropdown-selected");
				}
				dropdownText.value = DEFAULT_TEXT;
			}
		},
		{  flush: 'post'}
	);

	watchEffect(() => {
		try{
			nextTick(() => {
				const dropdownItemSelected = document.getElementById(props.inputId) as HTMLElement;
				if (dropdownItemSelected != null){
					if (props.selectedOption === '' || props.selectedOption == Guid.Empty) {
						if (dropdownItemSelected != null) {
							dropdownItemSelected.classList.remove("dropdown-selected");
						}
						dropdownText.value = DEFAULT_TEXT;
					} else {
						dropdownItemSelected.classList.add("dropdown-selected");
					}
				}
				nextTick(() => {
					if (typeof(props.validator) !== 'undefined' && props.validator != null) {
						// props.validator.setValidationRuleSourceValue(props.validatorRuleId, selectedOptionValue.value);
						ApplyValidate(!props.validator.isValidationRuleValid(props.validatorRuleId));
					}
				})
			});
		}
		catch{}	
	},
	{  flush: 'post'}
	);

	watch(() => props.refreshKey, async () => {
		await LoadData();
	});

	async function LoadData(){
		let results = await props.awaitOptions();
		if (results.length == 0 && props.options.length > 0)
		{
			dropDownOptions.value = props.options;
		}
		else 
		{
			dropDownOptions.value = results.map(_ => ({id: _['Value'], itemText: _['Text'], selected:false }))
		}

		if (dropDownOptions.value.length == 0){
			dropDownOptions.value = [{id: '', itemText: 'No options available', selected:false }]
		}
	}

	onMounted(async () =>{
		await LoadData();
	});

	onUnmounted(() => { 
		document.removeEventListener('click', closeDropdown);
	});
</script>